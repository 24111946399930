import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  FormControlLabel,
  Link,
  makeStyles,
  Paper,
  Switch,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Logo from "../Assets/logo.svg";
import SplashScreen from "./SplashScreen";

const APP_URL = "https://app.nhw-live.de";

const LOCAL_STORAGE_KEY = "doNotShowMessageAgain";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    left: 0,
    position: "fixed",
    top: 0,
    width: "100%",
  },
  link: {
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.light,
    },
    textDecoration: "none",
  },
}));

export default function ConsentMessage() {
  const classes = useStyles();

  const [loaded, setLoaded] = useState(false);
  const [redirecting, setRedirecting] = useState(false);

  const [checkboxDoNotShowAgain, setCheckboxDoNotShowAgain] = useState(false);

  const [openSettingsDialog, setOpenSettingsDialog] = useState(false);

  const [openDenyDialog, setOpenDenyDialog] = useState(false);

  const handleDenyClick = (e) => {
    setOpenDenyDialog(true);
  };

  const goToDestination = () => {
    console.log("On go to destination")
    const location = window.location;
    const newUrl = location.href.replace(location.origin, APP_URL);
    window.location = newUrl;
  };

  const handleAcceptClick = (e) => {
    setCheckboxDoNotShowAgain(true);
    setTimeout(() => {
      setRedirecting(true);
      window.localStorage.setItem(LOCAL_STORAGE_KEY, true);
      goToDestination();
      
    }, 300);
  };

  useEffect(() => {
    const storedValue = window.localStorage.getItem(LOCAL_STORAGE_KEY);
    if (storedValue === "true") {
      setRedirecting(true);
      goToDestination();
    } else {
      setLoaded(true);
    }
  }, []);

  if (!loaded || redirecting) {
    return <SplashScreen />;
  }

  return (
    <Box>
      <Dialog
        fullWidth
        maxWidth={"xs"}
        open={openDenyDialog}
        onClose={() => setOpenDenyDialog(false)}
      >
        <Box p={5}>
          <Typography variant="body2" color="textSecondary">
            Leider können wir Sie nicht an den Bestimmungsort weiterleiten.
          </Typography>
        </Box>
        <DialogActions>
          <Button color="primary" onClick={() => setOpenDenyDialog(false)}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Box className={classes.root}>
        <Box p={2}>
          <Paper>
            <Box p={4} maxWidth={650}>
              <Box display="flex" pb={2} justifyContent="left">
                <FormControlLabel
                  control={
                    <Switch
                      checked={checkboxDoNotShowAgain}
                      onChange={(e) =>
                        setCheckboxDoNotShowAgain(e.target.checked)
                      }
                      color="primary"
                    />
                  }
                  label={
                    <Typography variant="body2" color="textSecondary">
                      Einwilligung der Datenübertragung in die USA
                    </Typography>
                  }
                />
              </Box>
              <Box pl={6} pb={4}>
                <Typography variant="body2" color="textSecondary">
                  Wir verwenden auf unserer Website Dienste, insbesondere für
                  die das Streaming, für deren Verwendung wir Ihre Einwilligung
                  benötigen. Durch diese kommt es auch zu einer Übermittlung
                  Ihrer Daten in die USA. In den USA besteht kein der
                  Europäischen Union gleichwertiges Datenschutzniveau.
                  Weitergehende Informationen erhalten Sie durch ein Klick auf
                  den Button „mehr erfahren“. Ihre Einwilligung können Sie
                  jederzeit mit Wirkung für die Zukunft widerrufen, ohne dass
                  die bis dahin erfolgte Verarbeitung hiervon berührt wird. Die
                  Datenverarbeitung erfolgt entsprechend der{" "}
                  <Link
                    href="https://www.naheimst.de/datenschutz-nhw-live"
                    target="_blank"
                  >
                    Datenschutzerklärung
                  </Link>
                  .
                </Typography>
                <Box py={1}>
                  <Link
                    component="button"
                    onClick={() => setOpenSettingsDialog(!openSettingsDialog)}
                  >
                    mehr erfahren
                  </Link>
                </Box>

                <Collapse in={openSettingsDialog}>
                  <Typography variant="body2" color="textSecondary">
                    Wir verwenden Dienste, die Ihre IP-Adresse auch in die USA
                    übermittelt. Für die Übermittlung in die USA besteht weder
                    ein Angemessenheitsbeschluss der Europäischen Union noch
                    bestehen geeignete Garantien. Auf Ihre Daten können
                    amerikanische Behörden zugreifen und diese verwenden. Sowohl
                    Zugriff als auch die Verwendung Ihrer Daten sind nach den
                    amerikanischen Rechtsvorschriften nicht derart regelt, dass
                    dies dem europäischen Recht gleichwertig ist. Weder
                    enthalten diese Rechtsvorschriften Einschränkungen für den
                    Zugriff oder die Verwendung Ihrer Daten, noch steht Ihnen,
                    sofern Sie kein US-Bürger sind, ein effektiver Rechtsschutz
                    gegen den Zugriff und die Verwendung Ihrer Daten zur
                    Verfügung.
                  </Typography>
                </Collapse>
              </Box>

              <Box display="flex" mt={0} mb={1} justifyContent="center">
                <Box mr={2}>
                  <Button color="primary" onClick={handleDenyClick}>
                    Ablehnen
                  </Button>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAcceptClick}
                  >
                    alle Akzeptieren
                  </Button>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Box>
        <Box mt={0} textAlign="center" width="100%">
          <Typography variant="caption">
            <a
              href="https://www.naheimst.de/datenschutz-nhw-live"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Impressum
            </a>
          </Typography>
        </Box>
        <Box mt={4}>
          <img src={Logo} alt="" width={200} />
        </Box>
      </Box>
    </Box>
  );
}
