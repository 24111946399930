import React from "react";
import {
  Box /* CircularProgress */,
  makeStyles,
  useTheme,
  Typography,
} from "@material-ui/core";
import GridLoader from "react-spinners/GridLoader";
import Logo from "../Assets/logo.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    left: 0,
    padding: theme.spacing(3),
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 2000,
  },
  logo: {
    width: 200,
    maxWidth: "100%",
  },
  spinner: {
    border: "3px solid " + theme.palette.secondary.main,
  },
}));

function SlashScreen({ subtitle = null }) {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div className={classes.root}>
      <Box height={230} display="flex" flexDirection="column">
        <Box display="flex" justifyContent="center" mb={2}>
          <img src={Logo} alt="" style={{ maxHeight: 40 }} />
        </Box>
        <Box ml="auto" mr="auto" mt={1}>
          <GridLoader
            size={8}
            margin={4}
            color={theme.palette.primary.main}
          />
        </Box>
        {subtitle && (
          <Box mt={2}>
            <Typography variant="body2" color="textSecondary" align="center">
              {subtitle}
            </Typography>
          </Box>
        )}
      </Box>
    </div>
  );
}

export default SlashScreen;
