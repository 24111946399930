import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import ConsentMessage from "./Components/ConsentMessage";


const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#00519D",
    },
    secondary: {
      main: "#B82921",
    },
    background: {
      default: "#F4F6F8",
      paper: "#FFF",
    },
  },
});



function App() {

  return (
    <ThemeProvider theme={theme}>
              <ConsentMessage />

    </ThemeProvider>
  );
}

export default App;
